import React from "react"

import * as styles from "./styles.module.scss"

const PreviewText = ({ text }) => {
  return (
    <div className={styles.preview_text}>
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default PreviewText
