import React, { useEffect, useState } from "react"
import { doc, setDoc, Timestamp } from "firebase/firestore"
import { ref, uploadString } from "firebase/storage"
import { v4 as uuidv4 } from "uuid"

import { getDB, getST } from "../../utils/firebase"

import CenterTextArea from "../../components/commons/CenterTextArea"
import PreviewText from "../../components/commons/PreviewText"
import CommonHead from "../../components/commons/CommonHead"
import BottomButtonContainer from "../../components/commons/BottomButtonContainer"

import * as styles from "./styles.module.scss"
import { navigate } from "gatsby-link"

const Text = () => {
  const [text, setText] = useState("")
  const [inputName, setInputName] = useState("")
  const [isNameError, setIsNameError] = useState(false)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)

  const db = getDB()
  const storage = getST()

  const textChange = text => {
    setInputName(text)

    const length = text.length
    setIsNameError(length > 17)
    setIsSubmitEnabled(length < 18 && length > 0)
  }

  const getTimestamp = () => {
    return Timestamp.fromDate(new Date())
  }

  const postDocument = async uuid => {
    const text = sessionStorage.getItem("text")

    const data = {
      text: text,
      name: inputName,
      initial: text.charAt(0),
      create_at: getTimestamp(),
      status: "show",
    }

    const newKarutaRef = doc(db, "karuta", uuid)

    try {
      await setDoc(newKarutaRef, data)
      sessionStorage.clear()
      sessionStorage.setItem("created_id", uuid)
      navigate("/")
    } catch (e) {
      console.log("error")
      throw Error(e)
    }
  }

  const postImage = () => {
    const uuid = uuidv4()
    const imagePath = `images/karuta/${uuid}.png`
    const image = sessionStorage.getItem("image")
    const storageRef = ref(storage, imagePath)

    uploadString(storageRef, image, "data_url").then(snapshot => {
      postDocument(uuid)
    })
  }

  useEffect(() => {
    const text = sessionStorage.getItem("text")

    if (text) {
      setText(text)
    } else {
      navigate("/")
    }
  }, [])

  const handleClick = () => {
    postImage()
  }

  return (
    <main className={styles.name}>
      <CommonHead appendClass={styles.head}>名前を教えてね</CommonHead>
      <div className={`${styles.lead} ${isNameError && styles.error}`}>
        17字以内で入力してください
      </div>
      <CenterTextArea
        placeholder="なまえ"
        text={inputName}
        textChange={textChange}
      />
      <div className={styles.preview_container}>
        <PreviewText text={text} />
      </div>
      <BottomButtonContainer
        clickNext={handleClick}
        disabled={!isSubmitEnabled}
        clickPrev={() => navigate(-1)}
        isCheck={true}
      />
    </main>
  )
}

export default Text
