import React, { useRef, useState } from "react"

import * as styles from "./styles.module.scss"

const CenterTextArea = ({ placeholder, text, textChange }) => {
  const areaRef = useRef(null)
  const areaRefSp = useRef(null)

  const [fontSize, setFontSize] = useState(56)
  const [fontSizeSp, setFontSizeSp] = useState(28)

  const FONT_SIZE_LIST = {
    small: 22,
    middle: 32,
    large: 56,
  }

  const FONT_SIZE_LIST_SP = {
    small: 17,
    middle: 22,
    large: 28,
  }

  const between = (x, min, max) => {
    return x >= min && x <= max
  }

  const handleChange = e => {
    if (areaRef) {
      areaRef.current.style.height = `${areaRef.current.scrollHeight}px`
    }

    if (e.nativeEvent.inputType === "insertLineBreak") {
      // 改行を無視
      return
    }

    const input = e.target.value

    textChange(input)

    const count = input.length

    if (between(count, 0, 20)) {
      setFontSize(FONT_SIZE_LIST.large)
    } else if (between(count, 21, 45)) {
      setFontSize(FONT_SIZE_LIST.middle)
    } else {
      setFontSize(FONT_SIZE_LIST.small)
    }
  }

  const handleChangeSp = e => {
    if (areaRefSp) {
      areaRefSp.current.style.height = `${areaRefSp.current.scrollHeight}px`
    }

    if (e.nativeEvent.inputType === "insertLineBreak") {
      // 改行を無視
      return
    }

    const input = e.target.value

    textChange(input)

    const count = input.length

    if (between(count, 0, 20)) {
      setFontSizeSp(FONT_SIZE_LIST_SP.large)
    } else if (between(count, 21, 45)) {
      setFontSizeSp(FONT_SIZE_LIST_SP.middle)
    } else {
      setFontSizeSp(FONT_SIZE_LIST_SP.small)
    }
  }

  return (
    <div className={styles.textarea_container}>
      <textarea
        className={`${styles.textarea} ${styles.pc}`}
        ref={areaRef}
        placeholder={placeholder}
        style={{ fontSize: `${fontSize}px` }}
        value={text}
        onChange={handleChange}
      />
      <textarea
        className={`${styles.textarea} ${styles.sp}`}
        ref={areaRefSp}
        placeholder={placeholder}
        style={{ fontSize: `${fontSizeSp}px` }}
        value={text}
        onChange={handleChangeSp}
      />
    </div>
  )
}

export default CenterTextArea
